import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import AddShipmentForm from "../../../Forms/ShipmentForms/AddShipmentForm/AddShipmentForm";

export default function FormShipmentModal(props) {
  const [activeForm, setActiveForm] = useState({});
  const {
    action,
    handleModalAction,
    isModalActive,
    handleShowModal,
    order,
    wholesaler,
  } = props;

  const formModals = [
    {
      title: "Añadir recogida",
      action: "addShipment",
      component: (
        <AddShipmentForm
          handleShowModal={handleShowModal}
          handleModalAction={handleModalAction}
          order={order}
          wholesaler={wholesaler}
        />
      ),
      width: "30%",
    },
  ];

  useEffect(() => {
    let actionForm = formModals.find((form) => form.action === action);
    setActiveForm(actionForm);
  }, [action]);

  return (
    <div className="FormShipmentModal">
      <Modal
        width={activeForm?.width}
        visible={isModalActive}
        footer={null}
        maskClosable={true}
        title={activeForm?.title}
        onCancel={() => handleShowModal("", activeForm.action)}
        transitionName=""
      >
        {activeForm?.component}
      </Modal>
    </div>
  );
}
