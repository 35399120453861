import React, { Component } from "react";
import { Table, Skeleton, Tooltip, Row, Pagination } from "antd";
import moment from "moment";
import { ReactComponent as Weight } from "../../svgs/weight.svg";
import { ReactComponent as Package } from "../../svgs/package.svg";
import { ReactComponent as Pallet } from "../../svgs/pallet.svg";
import { ReactComponent as PaymentCard } from "../../svgs/paymentCard.svg";
import { ReactComponent as PaymentCash } from "../../svgs/paymentCash.svg";
import { ReactComponent as RequestPickup } from "../../svgs/scheduled.svg";
import { ReactComponent as NormalLabel } from "../../svgs/normalLabel.svg";
import { ReactComponent as TermicLabel } from "../../svgs/termicLabel.svg";
import { ReactComponent as Trash } from "../../svgs/trash.svg";

let handleLabelCreation;
let handleDeleteShipment;

let tableColumns = [
  {
    title: () => <span>Código</span>,
    dataIndex: "codShipment",
    align: "left",
    key: "codShipment",
    render: (code, row) => (
      <div className="code-container container-icons">
        {!row.pickupDate ? (
          <Tooltip title="Cancelar recogida">
            <Trash
              className="actionable-icon"
              onClick={() => handleDeleteShipment(row, "deleteShipment")}
            />
          </Tooltip>
        ) : (
          <div></div>
        )}
        <span>{code}</span>
      </div>
    ),
  },
  {
    title: "Destinatario",
    dataIndex: "recipientName",
    key: "recipientName",
  },
  {
    title: "Dirección",
    dataIndex: "recipientDirection",
    key: "recipientDirection",
    render: (addressee, row) => {
      const directionContent = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <span>
            {row.recipientDirection} {row.cp}, {row.recipientCityName}
          </span>
        </div>
      );
      return <span>{directionContent}</span>;
    },
  },
  {
    title: "Datos pedido",
    dataIndex: "shipmentDetails",
    align: "left",
    render: (order, row) => {
      return (
        <>
          <div
            className="data-container container-icons"
            style={{
              paddingTop: "2rem",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "1rem",
            }}
          >
            <Tooltip title="Peso (kg)">
              <Weight />
            </Tooltip>
            <Tooltip title="Bultos">
              <Package />
            </Tooltip>
            <Tooltip title="Pallets">
              <Pallet />
            </Tooltip>
            <Tooltip title="Método de pago">
              {row.indCash ? <PaymentCash /> : <PaymentCard />}
            </Tooltip>
          </div>
          <div
            className="data-container"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "1rem",
            }}
          >
            <span>{row.weight}</span>
            <span>{row.lumps}</span>
            <span>{row.pallets}</span>
            <span>{row.amount}</span>
          </div>
        </>
      );
    },
  },
  {
    title: "Fecha de registro",
    dataIndex: "recordDate",
    key: "recordDate",
    render: (order, row) => {
      return (
        <div className="date-container container-icons">
          <Tooltip title="Fecha de creación de la recogida">
            <span>
              {row.recordDate && moment(row.recordDate).format("DD/MM/YYYY")}
            </span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: "Recogida solicitada",
    dataIndex: "deliverDate",
    key: "deliverDate",
    render: (order, row) => {
      return (
        <div className="date-container container-icons">
          <Tooltip title="Fecha de solicitud de recogida">
            <RequestPickup />
          </Tooltip>
          <span>
            {row.requestPickupDate === null || !row.requestPickupDate
              ? "No disponible"
              : moment(row.requestPickupDate).format("DD/MM/YYYY")}
          </span>
        </div>
      );
    },
  },
  {
    title: "Acciones",
    dataIndex: "accionesPedido",
    render: (order, row) => {
      return (
        <>
          <div className="actions-container container-icons">
            <Tooltip title="Generar etiqueta normal">
              <NormalLabel
                onClick={() => handleLabelCreation(row, "P")}
                className="actionable-icon"
              />
            </Tooltip>
            <Tooltip
              title="Generar etiqueta térmica"
              onClick={() => handleLabelCreation(row, "I")}
              className="actionable-icon"
            >
              <TermicLabel />
            </Tooltip>
          </div>
        </>
      );
    },
  },
];
export default class ShipmentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "loading",
      data: [],
      statusCode: 0,
      total: 0,
      mustShowTable: false,
      currentPage: 1,
      size: 20,
      paginatedSearch: false,
    };
  }

  componentDidMount() {
    console.log("component is mounted, these are the props", this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.paginatedSearch) {
      this.handlePagination({
        size: this.state.size,
        currentPage: this.state.currentPage,
        paginatedSearch: this.state.paginatedSearch,
      });
    }

    if (prevProps.data !== this.props.data) {
      const { data, status, statusCode, totalResults } = this.props.data;

      if (statusCode === 0 && status === "paginating") {
        this.setState({
          mustShowTable: true,
          status: "paginating",
        });
        return false;
      }

      if (statusCode === 200) {
        this.setState({
          status: "loaded",
          data: data,
          statusCode: statusCode,
          total: totalResults,
          mustShowTable: true,
          page: data.number,
        });
      }

      if (statusCode !== 200) {
        this.setState({
          status: status,
          statusCode: statusCode,
          data: data,
          mustShowTable: false,
        });
      }
    }
  }

  handlePagination = (tableParameters) => {
    const searchParameters = JSON.parse(
      sessionStorage.getItem("searchParameters")
    );

    const { startDate, endDate, codWholesaler } = searchParameters;
    const { size, currentPage, paginatedSearch } = tableParameters;

    if (paginatedSearch) {
      this.props.fetchShipments(
        startDate,
        endDate,
        codWholesaler,
        size,
        currentPage,
        paginatedSearch
      );
    }
    this.setState({ paginatedSearch: false });
  };

  handlePagChange = (page, size) => {
    this.setState({
      currentPage: page,
      size: size,
      paginatedSearch: true,
      status: "paginating",
      mustShowTable: true,
    });
  };

  handlePagSizeChange = (current, size) => {
    this.setState({
      size: size,
      currentPage: 1,
      paginatedSearch: true,
      status: "paginating",
      mustShowTable: true,
    });
  };

  render() {
    handleLabelCreation = this.props.handleLabelCreation;
    handleDeleteShipment = this.props.handleDeleteShipment;

    const {
      data,
      statusCode,
      mustShowTable,
      status,
      total,
      size,
      currentPage,
    } = this.state;

    const columns = tableColumns.map((col) => {
      return col;
    });

    const pagProps = {
      total: total,
      showSizeChanger: true,
      showTotal: (total) => `${total} resultados`,
      hideOnSinglePage: false,
      defaultCurrent: 1,
      pageSize: size,
      pageSizeOptions: ["10", "20", "40", "80"],
    };

    return (
      <>
        <div className="ShipmentTable">
          {status === "loading" && <Skeleton active paragraph={{ rows: 4 }} />}

          {mustShowTable && statusCode === 200 && (
            <div>
              <h3>Historial de recogidas</h3>
              <Row
                type="flex"
                justify="end"
                style={{ padding: "1rem 0" }}
                className="pagination-row"
              >
                <Pagination
                  {...pagProps}
                  onChange={this.handlePagChange}
                  onShowSizeChange={this.handlePagSizeChange}
                />
              </Row>
              <div>
                <Table
                  bordered
                  rowKey={(record) => record.codShipment}
                  dataSource={data}
                  columns={columns}
                  loading={status === "paginating"}
                  pagination={false}
                />
              </div>
            </div>
          )}

          {!mustShowTable && statusCode === 204 && (
            <h2>La búsqueda no ha devuelto resultados.</h2>
          )}
        </div>
      </>
    );
  }
}
