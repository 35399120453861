import React, { useEffect, useState } from "react";
import { Button } from "antd";
import useShipments from "../../hooks/useShipments";
import OrderSearch from "../../components/OrderSearch/OrderSearch";
import ShipmentTable from "../../components/ShipmentTable/ShipmentTable";
import BasicShipmentModal from "../../components/Modals/ShipmentModals/BasicShipmentModal/BasicShipmentModal";
import FormShipmentModal from "../../components/Modals/ShipmentModals/FormShipmentModal/FormShipmentModal";
import useLabels from "../../hooks/useLabels";
import NotVerified from "../../components/NotVerified/NotVerified";

export default function Shipment({ userVerified }) {
  const { fetchShipments, shipmentData, addShipment, deleteShipment } =
    useShipments();

  const { createLabel } = useLabels();

  const [searchWasMade, setSearchWasMade] = useState(false);
  const [activeModal, setActiveModal] = useState({
    isModalActive: false,
    isModalFormActive: false,
    action: "",
    actionFunction: "",
    order: "",
    form: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [wholesalerInfo, setWholesalerInfo] = useState({
    wh: "",
    wht: "",
  });

  useEffect(() => {
    if (userVerified) {
      const userData = JSON.parse(sessionStorage.getItem("wh"));
      setWholesalerInfo({
        wh: userData.wh,
        wht: userData.wht,
      });

      setIsLoading(false);
    }
  }, [userVerified]);

  const getShipmentAction = (actionName) => {
    if (actionName === "addShipment") {
      return addShipment;
    }
    if (actionName === "deleteShipment") {
      return deleteShipment;
    }
  };

  const handleShowModal = (order, action) => {
    const { isModalFormActive, isModalActive } = activeModal;

    if (action === "addShipment") {
      if (!isModalFormActive) {
        setActiveModal((prevState) => ({
          ...prevState,
          isModalFormActive: true,
          action: action,
          actionFunction: getShipmentAction(action),
          order: order,
        }));
      }

      if (isModalFormActive) {
        setActiveModal((prevState) => ({
          ...prevState,
          isModalFormActive: false,
          action: "",
          actionFunction: "",
          order: "",
        }));
      }
    }

    if (action === "deleteShipment") {
      if (!isModalActive) {
        setActiveModal((prevState) => ({
          ...prevState,
          isModalActive: true,
          action: action,
          actionFunction: getShipmentAction(action),
          order: order,
        }));
      }

      if (isModalActive) {
        setActiveModal((prevState) => ({
          ...prevState,
          isModalActive: false,
          action: "",
          actionFunction: "",
          order: "",
        }));
      }
    }
  };

  const handleSearch = (searchProps) => {
    if (!searchWasMade) {
      setSearchWasMade(true);
    }
    const { startDate, endDate, codWholesaler } = searchProps;

    fetchShipments(startDate, endDate, codWholesaler);

    return false;
  };

  const handleModalAction = (shipmentDTO) => {
    try {
      const { actionFunction } = activeModal;
      const action = actionFunction(shipmentDTO);

      setActiveModal((prevState) => ({
        ...prevState,
        isModalActive: false,
        isModalFormActive: false,
        action: "",
        actionFunction: "",
        codShipment: "",
        form: null,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleLabelCreation = (shipmentData, labelType) => {
    const {
      codShipment,
      nameWholesaler,
      recipientDirection,
      cp,
      recipientCityName,
      weight,
      lumps,
      pallets,
    } = shipmentData;

    let senderPhone = wholesalerInfo.wht;

    if (!senderPhone) {
      const userData = JSON.parse(sessionStorage.getItem("wh"));

      if (userData) {
        senderPhone = userData.wht;
        setWholesalerInfo((prevState) => ({
          ...prevState,
          wht: senderPhone,
        }));
      }
    }

    const shipmentDTO = {
      codShipment: codShipment,
      sender: nameWholesaler,
      senderPhone: senderPhone,
      destiny: recipientDirection,
      postalCode: cp,
      location: recipientCityName,
      kilos: weight.toString(),
      packages: lumps.toString(),
      pallets: pallets.toString(),
    };

    createLabel(shipmentDTO, labelType);
  };

  return (
    <>
      {userVerified ? (
        <div className="Shipment">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Recogidas</h1>
            <Button
              onClick={() => handleShowModal("", "addShipment")}
              disabled={isLoading}
            >
              Nueva solicitud
            </Button>
          </div>
          <OrderSearch handleSearch={handleSearch} />
          {searchWasMade && (
            <ShipmentTable
              data={shipmentData}
              handleLabelCreation={handleLabelCreation}
              handleDeleteShipment={handleShowModal}
              fetchShipments={fetchShipments}
            />
          )}
          <BasicShipmentModal
            action={activeModal.action}
            handleModalAction={handleModalAction}
            isModalActive={activeModal.isModalActive}
            handleShowModal={handleShowModal}
            order={activeModal.order}
          />
          <FormShipmentModal
            action={activeModal.action}
            handleModalAction={handleModalAction}
            isModalActive={activeModal.isModalFormActive}
            handleShowModal={handleShowModal}
            order={activeModal.order}
            wholesaler={wholesalerInfo.wh}
          />
        </div>
      ) : (
        <NotVerified />
      )}
    </>
  );
}
