import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import useLogout from "../../Api/Auth/useLogout";

export default function NotVerified() {
  const { logout } = useLogout();
  const [mustShowMessage, setMustShowMessage] = useState(false);

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem("wh"));

    if (userInfo) {
      if (Object.keys(userInfo).includes("wh") && userInfo?.isVer === false) {
        setMustShowMessage(true);
      }
    }
  }, []);

  return (
    <div className="NotVerified">
      <div className="notVerifiedMessage">
        {mustShowMessage ? (
          <>
            <p>
              Está visualizando este mensaje porque su usuario no ha sido
              verificado por Correos y, por tanto, no tiene autorización para
              realizar esta operación.
            </p>
            <p>
              Pulse <button onClick={handleLogout}>aquí</button> para volver a
              la página de login.
            </p>
          </>
        ) : (
          <Spin size="large"></Spin>
        )}
      </div>
    </div>
  );
}
