import instance from "../Api/Instance";
import { back } from "../Api/ApiUrl";
import { saveAs } from "file-saver";
import { Modal } from "antd";
import { useState } from "react";

export default function useLabels() {
  const [labelData, setLabelData] = useState({
    status: "loading",
    labelDTO: "",
    statusCode: 0,
  });

  const generatePDF = (byte) => {
    const binaryData = new Uint8Array(byte);
    const blob = new Blob([binaryData], { type: "application/pdf" });

    saveAs(blob, "etiqueta.pdf");
  };

  const createLabel = (labelDetails, typeLabel) => {
    instance
      .post(back.createLabel, labelDetails, {
        params: {
          typeLabel: typeLabel,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const { data, status } = response;

        if (status === 200) {
          setLabelData((prevState) => ({
            ...prevState,
            status: "loaded",
            labelDTO: data,
            statusCode: 200,
          }));
          Modal.success({
            title: `Generación de etiqueta ${
              typeLabel === "I" ? "térmica" : ""
            } exitosa`,
            content: `Se ha generado la etiqueta ${
              typeLabel === "I" ? "térmica" : ""
            } correctamente`,
            onOk: () => generatePDF(data),
          });
        }

        if (status !== 200) {
          setLabelData((prevState) => ({
            ...prevState,
            status: "loaded",
            labelDTO: null,
            statusCode: status,
          }));
          Modal.warning({
            title: "Generación de etiqueta fallida",
            content:
              "No se ha podido generar la etiqueta correctamente. Inténtelo de nuevo más tarde.",
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: "Generación de etiqueta fallida",
          content:
            "No se ha podido generar la etiqueta correctamente.Inténtelo de nuevo más tarde.",
        });

        console.log(error);
      });
  };

  return { createLabel };
}
