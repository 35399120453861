import instance from "../Api/Instance";
import { back } from "../Api/ApiUrl";
import React, { useState } from "react";
import { Modal } from "antd";

export default function useAddressees() {
  const [addresseesData, setAddresseesData] = useState({
    status: "loading",
    data: [],
    statusCode: 0,
    message: "Cargando...",
    size: 10,
    page: 1,
  });

  const fetchAddressees = async (idWholesaler) => {
    instance
      .get(back.getAddressees, {
        params: {
          idWholesaler: idWholesaler,
        },
      })
      .then((response) => {
        const { data, status } = response;

        if (status === 200) {
          setAddresseesData((prevState) => ({
            ...prevState,
            status: "loaded",
            data: data,
            statusCode: status,
          }));
          return true;
        }

        if (status !== 200) {
          setAddresseesData((prevState) => ({
            ...prevState,
            data: [],
            statusCode: status,
          }));
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  const createAddressee = (addresseeDTO) => {
    if(addresseeDTO?.idAddressee == "") {
      delete addresseeDTO.idAddressee
    }
    instance
      .post(back.createAddressee, {
        ...addresseeDTO,
      })
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          if (addresseesData?.data?.length) {
            let newAddresseesArray = addresseesData.data.map((a) => {
              return { ...a };
            });
            newAddresseesArray.push(data);

            setAddresseesData((prevState) => ({
              ...prevState,
              data: newAddresseesArray,
            }));
          }
          return true;
        }

        if (response.status !== 200) {
          Modal.warning({
            title: "Ha habido un problema...",
            content:
              "No se ha podido realizar la creación del destinatario. Inténtelo más tarde",
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: "Se ha producido un error al guardar el destinatario",
          content: `Al intentar crear el nuevo registro se ha producido el siguiente error: ${error}`,
        });
      });
  };

  function deleteAddressee(idAddressee) {
    instance
      .delete(`${back.deleteAddressee}/${idAddressee}`)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          const filteredData = addresseesData.data.filter(
            (addressee) => addressee.idAddressee != idAddressee
          );
          setAddresseesData((prevState) => ({
            ...prevState,
            data: filteredData,
            status: "loaded",
            statusCode: status,
          }));
          return Modal.success({
            title: "Eliminación exitosa",
            content: "Se ha eliminado el destinatario correctamente",
          });
        }

        if (status !== 200) {
          setAddresseesData((prevState) => ({
            ...prevState,
            status: "loaded",
            statusCode: status,
          }));
          return Modal.warning({
            title: "Ha habido un problema...",
            content:
              "No se pudo eliminar el destinatario correctamente. Por favor, inténtelo de nuevo más tarde ",
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: "Se ha producido un error al guardar el destinatario",
          content: `Al intentar crear el nuevo registro se ha producido el siguiente error: ${error}`,
        });
      });
  }

  const fetchPostCodeInfo = async (postcode) => {
    let uniqueMunicipalities;

    try {
      const response = await instance.get(
        `${back.getPostCodeInfo}/${postcode}`
      );
      if (response) {
        const { data, status } = response;
        if (status === 200) {
          if (data.length) {
            uniqueMunicipalities = [
              ...new Set(data.map((d) => d.municipalityName)),
            ];
          }
          return uniqueMunicipalities;
        }
          Modal.error({
            title: "Información no encontrada",
            content: `El código postal introducido no existe.`,
          });
      }
    } catch (error) {
      Modal.error({
        title: "Información no encontrada",
        content: `El código postal introducido no existe.`,
      });
      return null;
    }
  };

  return {
    addresseesData,
    fetchAddressees,
    createAddressee,
    deleteAddressee,
    fetchPostCodeInfo,
  };
}
