import React, { useEffect, useState } from "react";
import { DatePicker, Select, Row, Col, Input, Button, Form } from "antd";
import useRoutes from "../../../../hooks/useRoutes";
import useAddressee from "../../../../hooks/useAddressees";
import moment from "moment";
import { dateFormatter, disabledDate } from "../../../../utils/utils";
import RecipientForm from "./RecipientForm";
const dateFormat = {
  en: "YYYY-MM-DD",
  sp: "DD-MM-YYYY",
};

function AddShipmentForm(props) {
  const { form, handleShowModal, handleModalAction, wholesaler } = props;
  const { getFieldDecorator } = form;
  const { Option } = Select;

  const [routes, setRoutes] = useState({
    message: "Buscando rutas disponibles",
    data: [],
  });
  const [receivedAddresseeData, setReceivedAddresseeData] = useState(null);
  const [dates, setDates] = useState([]);
  const [isIndCashEnabled, setIsIndCashEnabled] = useState(false);
  const { fetchRoutes, routesData } = useRoutes();
  const { createAddressee } = useAddressee();

  const userData = JSON.parse(sessionStorage.getItem("wh"));
  let userId = userData.wh;

  const fetchData = async () => {
    try {
      if (wholesaler) {
        setRoutes({ message: "Cargando...", data: [] });
        props.form.resetFields("idRoute");
        await fetchRoutes(wholesaler);
      } else {
        await fetchRoutes(userId);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const updateRoutesData = () => {
    if (routesData?.statusCode === 200 && routesData?.data?.length) {
      setRoutes({
        message: "Seleccione una ruta",
        data: routesData.data,
      });
    } else if (
      routesData.statusCode !== 200 &&
      routesData.status === "loaded"
    ) {
      setRoutes({ message: "No hay rutas disponibles", data: [] });
    }
  };

  useEffect(() => {
    fetchData();
  }, [wholesaler]);

  useEffect(() => {
    updateRoutesData();
  }, [routesData]);

  const onAddresseeDataToSave = (addresseeData) => {
    setReceivedAddresseeData(addresseeData);
  };

  const handleSelectedDate = (selectedDates) => {
    setDates(selectedDates);
  };

  const handleAmountChange = (value) => {
    const amount = parseFloat(value);
    setIsIndCashEnabled(amount > 0);
  };

  const validateAndHandleSubmit = async (e) => {
    e.preventDefault();
    try {
      await form.validateFields();
      const formValues = { ...form.getFieldsValue() };
      const formattedPickupDate = dateFormatter(formValues.requestPickupDate);
      formValues.requestPickupDate = formattedPickupDate;

      const updatedValues = {
        idWholesaler: wholesaler || userId,
        recipientName: formValues.recipientName,
        recipientDirection: formValues.recipientDirection,
        recipientCityName: formValues?.recipientCityName,
        cp: formValues.cp,
        requestPickupDate: formValues.requestPickupDate,
        weight: parseFloat(formValues.weight),
        realWeight: 0,
        realPallets: 0,
        lumps: parseFloat(formValues.lumps) || 0,
        pallets: parseFloat(formValues.pallets) || 0,
        amount: parseFloat(formValues.amount) || 0,
        indCash: formValues.indCash,
        idRoute: formValues.idRoute || null,
      };

      debugger
      if (receivedAddresseeData) {
        createAddressee(receivedAddresseeData);
      }

      updatedValues.idRoute === null && delete updatedValues.idRoute;

      handleModalAction(updatedValues);
    } catch (error) {
      console.error("Error de validación:", error);
    }
  };

  return (
    <div className="AddShipmentForm">
      <Form onSubmit={validateAndHandleSubmit}>
        <Row>
          <RecipientForm
            form={form}
            wholesaler={wholesaler}
            onAddresseeDataToSave={onAddresseeDataToSave}
          />
          <Row gutter={16}>
            <Col span={14}>
              <Form.Item label="Ruta">
                {getFieldDecorator("idRoute", {
                  rules: [{ required: false }],
                })(
                  <Select
                    size="large"
                    placeholder={routes.message}
                    className="addShipmentForm-select"
                    disabled={!routes?.data?.length}
                    loading={routes.message === "Cargando..."}
                  >
                    {routes?.data.length &&
                      routes.data.map((route) => (
                        <Option key={route.idRoute} value={route?.idRoute}>
                          {route?.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Fecha">
                {getFieldDecorator("requestPickupDate", {
                  initialValue: moment(),
                  rules: [
                    { required: true, message: "Debe introducir una fecha" },
                  ],
                })(
                  <DatePicker
                    format={dateFormat.sp}
                    disabledDate={disabledDate}
                    onChange={handleSelectedDate}
                    size="large"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Peso">
                {getFieldDecorator("weight", {
                  rules: [
                    { required: true, message: "Debe introducir el peso" },
                  ],
                })(
                  <Input
                    type="number"
                    size="large"
                    precision={2}
                    step={0.1}
                    placeholder="Kilos..."
                    min={0}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Bultos">
                {getFieldDecorator("lumps", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Input
                    type="number"
                    size="large"
                    placeholder="Bultos..."
                    min={0}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Pallets">
                {getFieldDecorator("pallets", {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Input
                    type="number"
                    size="large"
                    placeholder="Pallets..."
                    min={0}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Importe">
                {getFieldDecorator("amount", {
                  rules: [
                    {
                      required: false,
                      validator: (rule, value, callback) => {
                        if (
                          props.form.getFieldValue("indCash") === true &&
                          value >
                            Number(window._env_.REACT_APP_CASH_LIMIT_ALLOWED)
                        ) {
                          callback(
                            `El importe máximo para efectivo es ${window._env_.REACT_APP_CASH_LIMIT_ALLOWED}€`
                          );
                        } else {
                          callback();
                        }
                      },
                    },
                  ],
                })(
                  <Input
                    type="number"
                    size="large"
                    precision={2}
                    step={0.01}
                    placeholder="Importe..."
                    min={0}
                    onChange={(e) => handleAmountChange(e.target.value)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Método de cobro">
                {getFieldDecorator("indCash", {
                  initialValue: false,
                })(
                  <Select size="large" disabled={!isIndCashEnabled}>
                    <Option value={true}>Efectivo</Option>
                    <Option value={false}>Datáfono</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Row>
        <Row className="button-container">
          <Button
            type="danger"
            ghost
            onClick={() => handleShowModal("", "addShipment")}
          >
            Cancelar
          </Button>
          <Button htmlType="submit" className="ant-btn ant-btn-primary">
            Guardar
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default Form.create()(AddShipmentForm);
