import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const LoginCallback = () => {
  const queryParams = useLocation();
  const history = useHistory();
  
  const search = queryParams.search;

  const refreshToken = new URLSearchParams(search).get('refreshToken') ?? null;
  const idToken = new URLSearchParams(search).get('idToken') ?? null;
  
  useEffect(() => {
    if (idToken && refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("idToken", idToken);
      return history.push(`/authorize`);
    }
    if (!idToken && !refreshToken) {
      return history.push(`/login`);
    }
  }, []);
  return <div />;
}

export default LoginCallback