import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Loader from "./components/shared/Loader/Loader";
import "./main.scss";

// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

const MySuspense = (
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>
);

createRoot(document.getElementById("root")).render(MySuspense);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
