import React, { useState, useEffect } from "react";
import { Modal } from "antd";

export default function BasicShipmentModal({
  action,
  handleModalAction,
  isModalActive,
  handleShowModal,
  order,
}) {
  const [activeModalProperties, setActiveModalProperties] = useState(null);

  const handleCancelModal = () => {
    return handleShowModal("", action);
  };

  const basicModal = [
    {
      title: "Eliminar pedido",
      action: "deleteShipment",
      okText: "Borrar pedido",
      cancelText: "Cancelar",
      onOk: () =>
        handleModalAction({
          idShipment: order.idShipment,
          codShipment: order.codShipment,
        }),
      onCancel: () => handleCancelModal(),
      structure: (
        <div>
          <p>¿Desea eliminar este pedido?</p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    let pickedModal = basicModal.find((element) => element.action === action);

    setActiveModalProperties(pickedModal);
  }, [action]);

  return (
    <div className="BasicShipmentModal">
      <Modal
        title={activeModalProperties?.title}
        visible={isModalActive}
        onOk={activeModalProperties?.onOk}
        onCancel={handleCancelModal}
        okText={activeModalProperties?.okText}
        cancelButtonProps={{ type: "danger", ghost: true }}
        transitionName=""
      >
        {activeModalProperties?.structure}
      </Modal>
    </div>
  );
}
