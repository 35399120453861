import React, { useEffect } from 'react'
import axios from 'axios';
import { decodeToken } from 'correos-arq-auth/lib/UtilsLogin/UtilsLogin';
import { storeArq } from 'correos-arq-utils';
import rolesWeb from '../../router/rolesweb';
import rolesWebCId from '../../router/roleswebCId';
import { authCid } from '../ApiUrl';
import { useHistory } from 'react-router-dom'
const createHistory = require('history').createBrowserHistory;

const Authorize = () => {
  
  const history = createHistory({ forceRefresh: true });

  const clearTokenData = () => {
    storeArq.load();
    storeArq.data.auth.idToken = "";
    storeArq.data.auth.refreshToken = "";
    storeArq.save();
  };

  const checkValidAuth = async () => {
    try {
      const userdata = await axios.get(authCid.userDate, {
        headers: {
          'ApplicationOid': window._env_.REACT_APP_CLIENT_ID_C_ID,
          'Authorization': 'Bearer ' + storeArq.data.auth.idToken
        }
      });

      if (userdata.status == 200) {
        localStorage.removeItem('idToken')
        localStorage.removeItem('refreshToken')

        storeArq.data.auth.userInfo.nombre = userdata.data.name;
        storeArq.data.auth.userInfo.apellidos = userdata.data.surname1 + " " + userdata.data.surname2;
        storeArq.data.auth.userInfo.codired = ' ';
        storeArq.data.auth.userInfo.identificador = userdata.data.userEmail[0]?.email;
  
        storeArq.save();

        return true
      } else {
        clearTokenData();
        return false
      }
      
    } catch (error) {
      console.log("Error en autorización", error);
      clearTokenData();
      return false
    }
  }
  
  useEffect(() => {
     
    const idToken = localStorage.getItem('idToken');
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      if (idToken != null && refreshToken != null) {
        (async () => {
          storeArq.load();
          storeArq.data.auth.idToken = idToken;
          storeArq.data.auth.refreshToken = refreshToken;

          storeArq.save();

          decodeToken(storeArq.data.auth.idToken, rolesWeb, rolesWebCId);
          const validauth = await checkValidAuth();

          history.push("/");
        })();
      }
    } catch (error) {
      console.log("Ha habido un error en Authorize",error)
    }

  }, [])
  return <div />
}

export default Authorize