import React from "react";
import { Collapse } from "antd";
import OrderSearchForm from "../OrderSearchForm/OrderSearchForm";

export default function OrderSearch({ handleSearch }) {
  const { Panel } = Collapse;
  return (
    <div className="OrderSearch">
      <Collapse defaultActiveKey={["1"]}>
        <Panel header="Seleccione rango de fechas de recogidas registradas" key="1">
          <OrderSearchForm handleSearch={handleSearch}></OrderSearchForm>
        </Panel>
      </Collapse>
    </div>
  );
}
