/*eslint-env node*/

const back = {
  getShipments: window._env_.REACT_APP_ENDPOINT_BACKEND + "/shipments",
  getSingleShipment: window._env_.REACT_APP_ENDPOINT_BACKEND + "/shipment",
  getAddressees: window._env_.REACT_APP_ENDPOINT_BACKEND + "/addressees",
  getWholesaler: window._env_.REACT_APP_ENDPOINT_BACKEND + "/wholesalers",
  createWholesaler: window._env_.REACT_APP_ENDPOINT_BACKEND + "/wholesaler",
  updateWholesaler: window._env_.REACT_APP_ENDPOINT_BACKEND + "/wholesaler",
  deleteWholesaler: window._env_.REACT_APP_ENDPOINT_BACKEND + "/wholesaler",
  getUnits: window._env_.REACT_APP_ENDPOINT_BACKEND + "/units",
  createUnit: window._env_.REACT_APP_ENDPOINT_BACKEND + "/unit",
  updateUnit: window._env_.REACT_APP_ENDPOINT_BACKEND + "/unit",
  deleteUnit: window._env_.REACT_APP_ENDPOINT_BACKEND + "/unit",
  getRoutes: window._env_.REACT_APP_ENDPOINT_BACKEND + "/routes",
  getLabel: window._env_.REACT_APP_ENDPOINT_BACKEND + "/shippinglabel",
  updateShipment: window._env_.REACT_APP_ENDPOINT_BACKEND + "/shipment",
  deleteOrder: window._env_.REACT_APP_ENDPOINT_BACKEND + "/shipment",
  createShipment: window._env_.REACT_APP_ENDPOINT_BACKEND + "/shipment",
  createAddressee: window._env_.REACT_APP_ENDPOINT_BACKEND + "/addressee",
  deleteAddressee: window._env_.REACT_APP_ENDPOINT_BACKEND + "/addressee",
  createLabel: window._env_.REACT_APP_ENDPOINT_BACKEND + "/shippinglabel",
  getPostCodeInfo: window._env_.REACT_APP_ENDPOINT_BACKEND + "/localities",
};

const auth = {
  getTokenISAM:
    window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/mga/sps/oauth/oauth20/",
  logoutISAM: window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/pkmslogout",
  refreshTokenISAM:
    window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/[refreshTokenApi]",
  getTokenC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID +
    "/myidentity/notifier/signinorsignup",
  logoutC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID + "/myidentity/notifier/singout",
  refreshTokenC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID +
    "/myidentity/notifier/RefreshToken",
  userDataC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_API_C_ID + "/api/Accounts/user",
};

const authCid = {
  getAuthToken: window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/Login",
  refreshToken:
    window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/Login/RefreshToken",
  userDate: window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/ProfileUser",
  cancelToken:
    window._env_.REACT_APP_ENDPOINT_API_C_ID + "/api/Login/CancelToken",
};

module.exports = {
  back,
  auth,
  authCid,
};
