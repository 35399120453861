/* eslint-disable */
/* eslint-disable */
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Row, Col, Layout, ConfigProvider, Menu, Icon, Modal } from "antd";
import Spanish from "antd/lib/locale/es_ES";
import Header from "./components/Header/Header";
import { MLateral, BtnBurguer, MyRoutes } from "correos-arq-mlateral";
import { MHorizontal } from "correos-arq-mhorizontal";
import { BreadCrumb } from "correos-arq-breadcrumb";
import { PrivateRoute, Login, RedirectLogin } from "correos-arq-auth";
import { storeArq } from "correos-arq-utils";
import pathRoutes from "./router/routes";
import rolesWeb from "./router/rolesweb";
import rolesWebCId from "./router/roleswebCId";
import i18n from "./i18nConfig/i18n";
import { withTranslation } from "react-i18next";
import authRoutes from "./router/authRoutes";
import { CookieContext, cookies } from "./context/CookieContext";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import UserFooter from "./components/Footer/UserFooter";
import instance from "./Api/Instance";
import { back } from "./Api/ApiUrl";
// Declararación del Content del Layout de antd
const { Content } = Layout;
const styles = {
  HomeStyle: {
    margin: "0.357em",
  },
};

storeArq.load();

const createHistory = require("history").createBrowserHistory;

class App extends Component {
  //static contextType = CookieContext
  constructor(props) {
    super(props);
    require("dotenv").config();
    this.state = {
      showMenu: true, //Inicializa con el menu lateral abierto
      auth: storeArq.data.auth,
      areWeHome: true, //Indica si se esta en "/" para renderizar un estilo u otro
      reload: false, //Recargar app para actualizar submenus
      setInfo: false, //Indica cuando se pulsa el boton hamburguesa
      wholesalerId: null,
      codWholesaler: null,
      banner: cookies.showBanner,
      isUserVerified: false,
    };
  }
  history = createHistory({ forceRefresh: true });

  acceptCookies = () => {
    this.setState({ banner: !this.state.banner });
  };

  setInfoBtn = (info) => {
    const { showMenu } = this.state;
    showMenu && this.setState({ setInfo: info, showMenu: false });
    !showMenu && this.setState({ setInfo: info, showMenu: true });
  };

  UNSAFE_componentWillMount = () => {
    RedirectLogin();
  };

  clickHome = (key) => {
    this.setState({ areWeHome: key });
  };
  /* *
   * Muestra el menú o lo oculta
   * según el estado rederizado al hacer click al botón
   * */
  clickMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  clickMenuH = () => {
    const { showMenu } = this.state;
    !showMenu && this.setState({ showMenu: true });
  };

  /* *
   * Se usa en migasDePan para lanzar el render de la aplicacion por cambio de url
   * cambiando los submenus
   * */
  reloadApp = () => {
    this.setState({ reload: !this.state.reload });
  };

  fetchWholesaler = async (userInfo) => {
    if (!userInfo?.auth?.oid) {
      return false;
    }
    instance
      .get(back.getWholesaler, {
        params: {
          codWholesaler: userInfo?.auth?.oid,
        },
      })
      .then((response) => {
        const { status, data } = response;
        if (response.status === 200) {
          let wholesalerInfo = data.content.find(
            (user) => user?.codUsuCorreosId === userInfo?.auth?.oid
          );
          const { idWholesaler, phone, indVerified } = wholesalerInfo;
          this.setState({ isUserVerified: indVerified });

          sessionStorage.setItem(
            "wh",
            JSON.stringify({ wh: idWholesaler, wht: phone, isVer: indVerified })
          );

          if (response.status !== 200) {
            sessionStorage.setItem(
              "wh",
              JSON.stringify({ wh: null, wht: null, isVer: false })
            );
          }
          if (!indVerified) {
            Modal.error({
              title: "Usuario/a no verificado",
              content:
                "Actualmente no dispone de los permisos necesarios para utilizar esta aplicación. Póngase en contacto con Correos para activar su cuenta. Disculpe las molestias",
              okText: "Aceptar",
              onOk: () => this.userNotVerifiedLogout(),
              maskClosable: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  userNotVerifiedLogout = () => {
    storeArq.delete();
    sessionStorage.removeItem("wh");
    return this.history.push("/");
  };

  async componentDidMount() {
    const infoUser = JSON.parse(sessionStorage.getItem("storeArq.data"));
    const infoWh = JSON.parse(sessionStorage.getItem("wh"));

    if (infoWh) {
      this.state.isUserVerified = infoWh.isVer;
    }

    if (!infoWh) {
      await this.fetchWholesaler(infoUser);
    }

    const id = infoUser?.auth?.userInfo?.identificador;

    if (id) {
      //id del session storage
      const id64 = btoa(id);

      //id del local storage
      const cookie = JSON.parse(localStorage.getItem(id64));

      //si existe el id del sesion storage en local storage cookieaccepted = true
      if (cookie) {
        //setear el cokieaccepted a true
        this.acceptCookies();
      }
    }
  }

  render() {
    const { t } = this.props;
    const { showMenu } = this.state;
    storeArq.load();
    let subMenu = this.state.auth.userInfo.subMenu || {
      childs: [],
      parent: "",
    };
    let menuLateral = "";
    let migasDePan = "";
    let menuPPal = "";
    let menuLatBoton = null;
    let newPathRoutes = MyRoutes.getNewPathRoutes(pathRoutes);

    subMenu = MyRoutes.getSubMenu(pathRoutes, subMenu);

    //Comprobar si hay submenus para renderizar el boton de menu lateral
    subMenu.childs.length > 0
      ? (menuLatBoton = (
          <BtnBurguer showMenu={showMenu} setInfoBtn={this.setInfoBtn} />
        ))
      : (menuLatBoton = null);

    if (
      this.state.auth.idToken === "" ||
      window.location.pathname === "/login"
    ) {
      menuPPal = <div className="MHorizontal" />;
    } else {
      menuPPal = (
        <MHorizontal
          items={pathRoutes}
          pathRoutes={pathRoutes}
          clickMenu={this.clickMenuH}
          areWeHome={this.state.areWeHome}
          clickHome={this.clickHome}
        />
      );

      if (showMenu) {
        menuLateral = (
          <Row>
            <Col lg={4} style={{ width: "auto" }}>
              <MLateral
                longItem="20"
                items={subMenu}
                reloadApp={this.reloadApp}
              />
            </Col>
          </Row>
        );

        migasDePan = (
          <Row>
            <Col span={1} className="ContainBtn">
              {menuLatBoton}
            </Col>
          </Row>
        );
      } else {
        menuLateral = (
          <Row>
            <Col span={1}>{menuLatBoton}</Col>
          </Row>
        );
      }
    }
    return (
      <ConfigProvider locale={Spanish}>
        <CookieContext.Provider value={this.state.banner}>
          <Router>
            <div className="App" data-version="0.2.92">
              <Layout className="layout">
                <Header
                  clickHome={this.clickHome}
                  clickMenu={showMenu && this.clickMenu}
                  i18n={i18n}
                  logoutSys={true}
                  pathHelp={"/ayuda"}
                />
                <Layout className="layout">{menuPPal}</Layout>
                <Layout className="layout">
                  <Row>
                    <Col span={1} />
                    <Col lg={22}>
                      <Layout className="layout">
                        {menuLateral}
                        <Layout className="layout">
                          {migasDePan}
                          <Content
                            className={
                              showMenu === true ? "ContentSpace" : "Contenido"
                            }
                            style={
                              window.location.pathname === "/" ||
                              window.location.pathname === "/salidadatos" ||
                              window.location.pathname === "/RutaPrivada" ||
                              window.location.pathname === "/destinatarios" ||
                              window.location.pathname === "/avisolegal" ||
                              window.location.pathname === "/politicacookies" ||
                              window.location.pathname === "/privacidadweb" ||
                              window.location.pathname === "/protecciondatos"
                                ? styles.HomeStyle
                                : null
                            }
                          >
                            <CookieBanner />
                            <Switch>
                              {newPathRoutes.map(({ component, path }) => (
                                <PrivateRoute
                                  exact
                                  key={path}
                                  path={path}
                                  component={component}
                                  auth={this.state.auth}
                                  userVerified={this.state.isUserVerified}
                                />
                              ))}
                              {/* <Route
                                path="/login"
                                render={(props) => (
                                  <Login
                                    rolesWeb={rolesWeb}
                                    rolesWebCId={rolesWebCId}
                                    i18n={i18n}
                                    tipoIntegracion="correosid"
                                    {...props}
                                  />
                                )}
                              /> */}
                              {authRoutes.map((route) => {
                                return (
                                  <Route
                                    key={route.id}
                                    path={route.path}
                                    component={
                                      route.type == "function"
                                        ? route.component
                                        : null
                                    }
                                  />
                                );
                              })}
                            </Switch>
                          </Content>
                        </Layout>
                      </Layout>
                    </Col>
                    <Col span={1} />
                  </Row>
                  <UserFooter />
                </Layout>
              </Layout>
            </div>
          </Router>
        </CookieContext.Provider>
      </ConfigProvider>
    );
  }
}

export default withTranslation()(App);
