import moment from "moment";

export const dateFormatter = (date) => {
    let formattedDate = date;
    formattedDate.hour(23);
    formattedDate.minute(59);
    formattedDate.second(59);
    return formattedDate;
  };
export const disabledDate = (current) => {
    return (
      current &&
      (current < moment().startOf("day") || current > moment().add(1, "day"))
    );
  };
  
  