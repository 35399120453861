import instance from "../Api/Instance";
import { back } from "../Api/ApiUrl";
import { useState } from "react";

export default function useRoutes() {
  const [routesData, setRoutesData] = useState({
    status: "loading",
    data: [],
    statusCode: 0,
  });

  const fetchRoutes = (idWholesaler) => {
    instance
      .get(back.getRoutes, {
        params: {
          idWholesaler: idWholesaler,
        },
      })
      .then((response) => {
        const { data, status } = response;
        if (response.status === 200) {
          return setRoutesData((prevState) => ({
            ...prevState,
            status: "loaded",
            data: data,
            statusCode: status,
          }));
        }

        if (response.status !== 200) {
          return setRoutesData((prevState) => ({
            ...prevState,
            status: "loaded",
            data: [],
            statusCode: status,
          }));
        }
      })
      .catch((error) => console.log(error));
  };

  return { fetchRoutes, routesData };
}
