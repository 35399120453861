import React, { useState, useEffect } from "react";
import { Form, Input, Checkbox, Row, Col, Select } from "antd";
import useAddressees from "../../../../hooks/useAddressees";
import RecipientCityField from "./RecipientCityField";

const { Search } = Input;
function RecipientForm({
  form,
  wholesaler,
  onAddresseeDataToSave
}) {
  const { getFieldDecorator, setFieldsValue, resetFields } = form;
  const { Option } = Select;

  const userData = JSON.parse(sessionStorage.getItem("wh"));
  let userId = userData.wh;

  const [formData, setFormData] = useState({
    addressee: "",
    addressees: [],
    saveAddressee: false,
    formAddresseeData: {
      idAddressee: "",
      idWholesaler: wholesaler,
      name: "",
      direction: "",
      cp: "",
      nomLocation: "",
      recordDate: "",
    },
  });

  const [selectedAddressee, setSelectedAddressee] = useState({
    mustUpdate: false,
    name: "",
    direction: "",
    cp: "",
    nomLocation: "",
  });

  const [postCodeInfo, setPostCodeInfo] = useState({
    status: "idle",
    data: [],
  });

  const { addresseesData, fetchAddressees, fetchPostCodeInfo } =
    useAddressees();


  useEffect(() => {
    if (formData?.saveAddressee) {
      const formValues = { ...form.getFieldsValue() };
      const updatedAddresseeData = {
        ...formData?.formAddresseeData,
        name: formValues.recipientName,
        direction: formValues.recipientDirection,
        cp: formValues.cp,
        nomLocation: formValues?.recipientCityName,
      };
      setFormData({
        ...formData,
        formAddresseeData: updatedAddresseeData,
      });
      onAddresseeDataToSave(updatedAddresseeData);
    }
  }, [formData?.saveAddressee]);

  useEffect(() => {
    fetchData();
  }, [wholesaler]);

  useEffect(() => {
    updateAddresseeData();
  }, [addresseesData.data]);

  useEffect(() => {
    // actualiza el formulario si seleccionamos addressee
    if (selectedAddressee.mustUpdate) {
      setFieldsValue({
        recipientName: selectedAddressee.name || "",
        recipientDirection: selectedAddressee.direction || "",
        cp: selectedAddressee.cp || "",
        recipientCityName: selectedAddressee.nomLocation || "",
      });
    }
  }, [selectedAddressee.mustUpdate]);

  const updateAddresseeData = () => {
    if (addresseesData.data.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        addressees: addresseesData.data,
        formAddresseeData: {
          ...prevState.formAddresseeData,
          // idAddressee: addresseesData.data.length + 1,
          idWholesaler: userId,
        },
      }));
    }
  };

  const fetchData = async () => {
    try {
      if (wholesaler) {
        await fetchAddressees(wholesaler);
      } else {
        await fetchAddressees(userId);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleSelectedAddressee = (optionSelected) => {
    setSelectedAddressee((prevState) => ({
      ...prevState,
      mustUpdate: true,
    }));
    setPostCodeInfo({
      status: "idle",
      data: [],
    });
    setSelectedAddressee((prevState) => ({
      ...prevState,
      ...optionSelected,
      mustUpdate: false,
    }));
    setFieldsValue({
      cp: optionSelected.cp,
      recipientCityName: optionSelected.nomLocation,
    });
  };

  const getPostalCodes = async (e) => {
    const postalCode = e.target.value;
    setSelectedAddressee((prevState) => ({
      ...prevState,
      nomLocation: "",
    }));
    setFieldsValue({
      recipientCityName: "",
    });

    try {
      if (postalCode.length === 5) {
        setPostCodeInfo((prevState) => ({
          ...prevState,
          status: "loading",
          data: [],
        }));
        const result = await fetchPostCodeInfo(postalCode);
        if (result) {
          setPostCodeInfo({
            status: "loaded",
            data: result,
          });
          return true;
        }
        setPostCodeInfo({
          status: "loaded",
          data: [],
        });

        setFieldsValue({
          cp: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Form.Item label="Buscar destinatario">
        {getFieldDecorator("selectAddressee", {
          rules: [
            {
              required: false,
            },
          ],
        })(
          <Select
            size="large"
            className="addShipmentForm-select"
            onChange={(option) => {
              setFormData({ ...formData, addressee: option });
            }}
            placeholder={
              addresseesData.status === "loaded"
                ? "Destinatarios registrados anteriormente"
                : "Cargando datos..."
            }
            loading={!formData.addressees?.length}
            allowClear={true}
          >
            {formData.addressees?.length &&
              formData.addressees?.map((addressee) => (
                <Option
                  key={addressee.idAddressee}
                  value={addressee.idAddressee}
                  onClick={() => handleSelectedAddressee(addressee)}
                >
                  {addressee.name}
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Razón social" name="recipientName">
        {getFieldDecorator("recipientName", {
          initialValue: selectedAddressee?.name || "",
          rules: [
            { required: true, message: "Debe introducir un destinatario" },
          ],
        })(
          <Input
            size="large"
            placeholder="Empresa de destino / Nombre y apellidos de destinatario..."
          />
        )}
      </Form.Item>
      <Form.Item label="Dirección" name="recipientDirection">
        {getFieldDecorator("recipientDirection", {
          initialValue: selectedAddressee?.direction || "",
          rules: [{ required: true, message: "Debe introducir una dirección" }],
        })(<Input size="large" placeholder="Dirección del destinatario..." />)}
      </Form.Item>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Código Postal" name="cp">
            {getFieldDecorator("cp", {
              initialValue: selectedAddressee?.cp || "",
              rules: [
                { required: true, message: "Debe introducir un código postal" },
              ],
            })(
              <Search
                size="large"
                placeholder="Código postal..."
                onChange={getPostalCodes}
                loading={postCodeInfo.status === "loading"}
                maxLength={5}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={16}>
          <RecipientCityField
            form={form}
            postCodeInfo={postCodeInfo}
            selectedAddressee={selectedAddressee}
          />
        </Col>
        <Col span={16} offset={14}>
          <Form.Item name="saveAddresse">
            {getFieldDecorator("saveAddressee", {
              valuePropName: "checked",
            })(
              <Checkbox
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    saveAddressee: e.target.checked,
                  });
                }}
              >
                Guardar datos destinatario
              </Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default Form.create()(RecipientForm);
