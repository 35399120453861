import React, { useEffect } from "react";
import { Form, Input, Select, Tooltip } from "antd";
export default function RecipientCityField(props) {
  const { postCodeInfo, selectedAddressee, form } = props;
  const { Option } = Select;
  const { getFieldDecorator, setFieldsValue, resetFields } = form;
 
  const getProperSelect = () => {
    if (postCodeInfo.data.length == 1) {
  
      return getFieldDecorator( "recipientCityName", {
        initialValue: postCodeInfo.data[0],
        rules: [
          {
            required: true,
            message: "Debe introducir una población",
          },
        ],
      })(
        <Input size="large" disabled value={postCodeInfo.data[0]} />
      );
    }
    return getFieldDecorator("recipientCityName", {
      initialValue: postCodeInfo.data.length == 1 ? postCodeInfo.data[0] : "",
      rules: [
        {
          required: true,
          message: "Debe introducir una población",
        },
      ],
    })(
      <Select
        size="large"
        placeholder="Seleccione población..."
        disabled={!postCodeInfo.data.length}
        defaultOpen
      >
        {postCodeInfo?.data.map((option) => {
          return (
            <Option value={option} key={option}>
              {option}
            </Option>
          );
        })}
      </Select>
    );
  };

  useEffect(() => {
    if(postCodeInfo.data.length == 1){
      setFieldsValue({
        recipientCityName: postCodeInfo.data[0],
      });
    }
  }, [postCodeInfo]);

  return (
    <Form.Item label="Población" name="recipientCityName">
      {postCodeInfo.status === "loading" ||
      (!postCodeInfo.data.length && selectedAddressee.nomLocation)
        ? getFieldDecorator("recipientCityName", {
            initialValue: selectedAddressee?.nomLocation || "",
            rules: [
              {
                required: true,
                message: "Debe introducir una población",
              },
            ],
          })(
            <Input
              size="large"
              placeholder={
                selectedAddressee.nomLocation
                  ? selectedAddressee.nomLocation
                  : "Cargando poblaciones..."
              }
              disabled
            />
          )
        : getProperSelect()}
    </Form.Item>
  );
}
