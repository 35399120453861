import { withTranslation } from "react-i18next";
import React,{ useState, useEffect } from "react"; 
import { Layout, Modal, Typography } from "antd";

const { Footer } = Layout;
const UserFooter = () => {
  const allDocuments = [
    { url: "../../documents/AvisoLegal.html", name: "Aviso Legal" },
    {
      url: "../../documents/PrivacidadWeb.html",
      name: "Política de privacidad web",
    },
    {
      url: "../../documents/PoliticaCookies.html",
      name: "Política de Cookies",
    },
    {
      url: "../../documents/PoliticaProteccionDeDatos.html",
      name: "Política de Protección de Datos",
    },
  ];
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ name: '', htmlContent: '' });

  const openModal = (url, name) => {
    fetch(url)
      .then(response => response.text())
      .then(htmlContent => setModalContent({ name, htmlContent }))
      .catch(error => {
        console.error('Error fetching document:', error);
        setModalContent({ name: '', htmlContent: '' });
      });

    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalContent({ name: '', htmlContent: '' });
  };

  const handleModalLinkClick = (e) => {
    const targetUrl = e.target.getAttribute('href');

    const matchedDocument = allDocuments.find(doc => doc.url === targetUrl);

    if (matchedDocument) {
      e.preventDefault();
      openModal(matchedDocument.url, matchedDocument.name);
    }
  };

  useEffect(() => {
    if (modalVisible) {
      setModalVisible(false);
      setTimeout(() => setModalVisible(true), 100);
    }
  }, [modalContent]);

  return (
    <Footer className='footer'>
      <ul>
        <img src='../../images/LogoCorreos_blanco_130px.svg' alt='Logo' />
        {allDocuments.slice(0, allDocuments.length - 1).map((doc, index) => (
          <li key={index}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openModal(doc.url, doc.name);
              }}
            >
              {doc.name}
            </a>
          </li>
        ))}
      </ul>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        width='66%'
      >
        <div onClick={handleModalLinkClick}>
          <div dangerouslySetInnerHTML={{ __html: modalContent.htmlContent }} />
        </div>
      </Modal>
    </Footer>
  );
};

export default withTranslation()(UserFooter);
