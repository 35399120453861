import {
  Shipment,
  Addressees,
} from "../pages";

const pathRoutes = [
  {
    path: "/",
    component: Shipment,
    label: "Recogidas",
    public: true,
  },
  {
    path: "/destinatarios",
    component: Addressees,
    label: "router_routes.addressees",
    public: true,
  }
];

export default pathRoutes;
