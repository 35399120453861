import instance from "../Api/Instance";
import { back } from "../Api/ApiUrl";
import { useState } from "react";
import { Modal } from "antd";

export default function useShipments() {
  const [shipmentData, setShipmentData] = useState({
    status: "loading",
    data: [],
    statusCode: 0,
    message: "Cargando...",
    size: 20,
    page: 1,
    totalResults: 0,
    totalPages: 0,
    tableVisible: false,
  });

  const singleDateFormatter = (date) => {
    let formattedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    return formattedDate;
  };

  const datesFormatter = (dates) => {
    let startDate = new Date(dates.startDate);
    let endDate = new Date(dates.endDate);

    let formattedStartDate = singleDateFormatter(startDate);

    let formattedEndDate = singleDateFormatter(endDate);

    return { formattedStartDate, formattedEndDate };
  };

  const fetchShipments = (
    startDate,
    endDate,
    codWholesaler,
    size,
    page,
    pagination
  ) => {
    if (!pagination) {
      sessionStorage.setItem(
        "searchParameters",
        JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          codWholesaler: codWholesaler,
          // sort: "requestPickupDate,desc",
        })
      );
    }

    setShipmentData((prevState) => ({
      ...prevState,
      status: pagination ? "paginating" : "loading",
      statusCode: 0,
      data: [],
    }));

    const searchParams = {
      startDate: startDate,
      endDate: endDate,
      codWholesaler: codWholesaler,
      size: size || 20,
      page: page - 1 || 0,
      pagination: pagination,
      // sort: "requestPickupDate,desc",
    };

    for (let key in searchParams) {
      if (!searchParams[key]) {
        delete searchParams[key];
      }
    }

    instance
      .get(back.getShipments, {
        params: {
          ...searchParams,
        },
      })
      .then((response) => {
        const { data, status } = response;

        if (status === 200) {
          return setShipmentData((prevState) => ({
            ...prevState,
            status: "loaded",
            data: data.content,
            statusCode: status,
            message: "",
            size: data.size,
            page: data.pageable.pageNumber,
            totalResults: data.totalElements,
            totalPages: data.totalPages,
            tableVisible: true,
          }));
        }

        if (status !== 200) {
          return setShipmentData((prevState) => ({
            ...prevState,
            status: "loaded",
            data: [],
            statusCode: status,
            message: "",
          }));
        }
      })
      .catch((error) => {
        console.log(error);
        setShipmentData((prevState) => ({
          ...prevState,
          status: "loaded",
          data: [],
          statusCode: 500,
          message: "",
        }));
      });
  };

  const addShipment = (shipmentDTO) => {
    const datesToCompare = JSON.parse(
      sessionStorage.getItem("searchParameters")
    );

    instance
      .post(back.createShipment, {
        ...shipmentDTO,
      })
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          const { codShipment, recipientName, requestPickupDate } = data;

          if (shipmentData.data.length && datesToCompare) {
            const { formattedStartDate, formattedEndDate } =
              datesFormatter(datesToCompare);

            let requestPickupDateToCompare = new Date(requestPickupDate);
            let formattedRequestPickupDate = singleDateFormatter(
              requestPickupDateToCompare
            );

            let orderIsWithinDateRange =
              formattedRequestPickupDate >= formattedStartDate &&
              formattedRequestPickupDate <= formattedEndDate;

            if (orderIsWithinDateRange) {
              let updatedDataArray = JSON.parse(
                JSON.stringify(shipmentData.data)
              );
              let orderCanFitTableSize =
                shipmentData.size > shipmentData.data.length;

              if (orderCanFitTableSize) {
                updatedDataArray.push(data);

                setShipmentData((prevState) => {
                  const updatedTotalResults = (prevState.totalResults += 1);
                  return {
                    ...prevState,
                    data: updatedDataArray,
                    totalResults: updatedTotalResults,
                  };
                });
              }

              if (!orderCanFitTableSize) {
                setShipmentData((prevState) => {
                  const updatedTotalResults = (prevState.totalResults += 1);
                  return {
                    ...prevState,
                    totalResults: updatedTotalResults,
                  };
                });

                const searchParameters = JSON.parse(
                  sessionStorage.getItem("searchParameters")
                );
                const startDate = searchParameters.startDate;
                const endDate = searchParameters.endDate;
                const codWholesaler = searchParameters.codWholesaler;

                fetchShipments(
                  startDate,
                  endDate,
                  codWholesaler,
                  shipmentData.size,
                  shipmentData.page,
                  true
                );
              }
            }
          }
          if (status === 200) {
            Modal.success({
              title: "Procesos realizados correctamente",
              content: `Se ha registrado con éxito el envío con código ${codShipment} para el destinatario ${recipientName}.`,
            });
          }
        }

        if (status !== 200) {
          Modal.warning({
            title: "Ha habido un problema...",
            content:
              "No se ha podido realizar la creación del pedido. Inténtelo más tarde",
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: "Se ha producido un error",
          content: `Al intentar crear el nuevo pedido se ha producido el siguiente error: ${error}`,
        });
      });
  };

  const deleteShipment = (shipmentDTO) => {
    const { idShipment, codShipment } = shipmentDTO;
    instance
      .delete(`${back.deleteOrder}/${idShipment}`)
      .then((response) => {
        const { status } = response;
        if (status !== 200) {
          return Modal.error({
            title: "Error encontrado",
            content:
              "No ha sido posible realizar la petición, inténtelo más tarde.",
            centered: true,
          });
        }

        if (status === 200) {
          setShipmentData((prevState) => {
            const updatedTotalResults = (prevState.totalResults -= 1);
            return {
              ...prevState,
              data: shipmentData.data.filter(
                (order) => order.idShipment !== idShipment
              ),
              totalResults: updatedTotalResults,
            };
          });

          Modal.success({
            title: "Proceso realizado correctamente",
            content: `Se ha borrado el pedido ${codShipment} correctamente.`,
          });
          return true;
        }
      })
      .catch((error) => {
        Modal.error({
          title: "Se ha producido un error",
          content: `No se ha podido eliminar el pedido ${codShipment}. Inténtelo de nuevo más tarde. `,
        });
      });
  };

  const updateShipment = (shipmentDTO) => {
    let eventName;

    if (Object.keys(shipmentDTO).includes("pickupDate")) {
      eventName = "El evento de recogida";
    }

    if (Object.keys(shipmentDTO).includes("deliverDate")) {
      eventName = "El evento de entrega";
    }

    if (
      Object.keys(shipmentDTO).includes("pickupDate") &&
      Object.keys(shipmentDTO).includes("deliverDate")
    ) {
      eventName = "Los eventos de recogida y entrega";
    }

    if (Object.keys(shipmentDTO).includes("realPallets")) {
      eventName = "El registro del número real de pallets";
    }

    if (Object.keys(shipmentDTO).includes("realWeight")) {
      eventName = "El registro del peso real";
    }

    if (Object.keys(shipmentDTO).includes("indCash")) {
      eventName = "Los registros de la forma de pago y su importe";
    }

    instance
      .put(
        back.updateShipment,
        { ...shipmentDTO },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let newShipmentsArray = shipmentData.data.map((shipment) => {
            if (shipment.codShipment === response.data.codShipment) {
              return { ...shipment, ...shipmentDTO };
            }
            return shipment;
          });

          setShipmentData((prevState) => ({
            ...prevState,
            data: newShipmentsArray,
          }));

          Modal.success({
            title: "Proceso realizado correctamente",
            content:
              Object.keys(shipmentDTO).length < 3
                ? `${eventName} del pedido ${shipmentDTO.codShipment} se ha realizado correctamente.`
                : `${eventName} del pedido ${shipmentDTO.codShipment} se han realizado correctamente.`,
          });
        }

        if (response.status !== 200) {
          Modal.warning({
            title: "El proceso no ha realizado cambios en el pedido",
            content: "",
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: "Ha habido un problema...",
          content:
            Object.keys(shipmentDTO).length < 3
              ? `${eventName} del pedido ${shipmentDTO.codShipment} no se pudo realizar debido al siguiente error: ${error}.`
              : `${eventName} del pedido ${shipmentDTO.codShipment} se pudieron realizar correctamente debido al siguiente error: ${error}.`,
        });
      });
  };

  return {
    fetchShipments,
    shipmentData,
    updateShipment,
    deleteShipment,
    addShipment,
  };
}
