import React, { Component } from "react";
import { Table, Skeleton } from "antd";
import { ReactComponent as Trash } from "../../svgs/trash.svg";

let handleModal;

const tableColumns = [
  {
    title: () => <span>Nombre</span>,
    dataIndex: "name",
    align: "left",
    key: "name",
    render: (name, row) => <span>{name}</span>,
  },
  {
    title: () => <span>Dirección</span>,
    dataIndex: "direction",
    align: "left",
    key: "direction",
    render: (direction, row) => <span>{direction}</span>,
  },
  {
    title: () => <span>Código Postal</span>,
    dataIndex: "cp",
    align: "left",
    key: "cp",
    render: (cp, row) => <span>{cp}</span>,
  },
  {
    title: () => <span>Población</span>,
    dataIndex: "nomLocation",
    align: "left",
    key: "nomLocation",
    render: (nomLocation, row) => <span>{nomLocation}</span>,
  },
  {
    title: () => <span></span>,
    dataIndex: "action",
    align: "center",
    key: "action",
    render: (nomLocation, row) => (
      <div className="Trash">
        <Trash className="trash-icon stroke" onClick={() => handleModal(row)} />
      </div>
    ),
    width: 60,
  },
];

export default class AddresseesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "loading",
      data: [],
      statusCode: 0,
      totalResults: 0,
      mustShowTable: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.addresseesData != this.props.addresseesData) {
      const { data, status, statusCode } = this.props.addresseesData;
      if (statusCode === 200) {
        this.setState({
          status: "loaded",
          data: data,
          statusCode: statusCode,
          mustShowTable: true,
        });
      }

      if (statusCode !== 200) {
        this.setState({
          status: status,
          statusCode: statusCode,
          data: data,
          mustShowTable: false,
        });
      }
    }
  }

  render() {
    handleModal = this.props.handleModal;
    const { data, statusCode, mustShowTable, status } = this.state;

    const columns = tableColumns.map((col) => col);
    return (
      <div className="AddresseesTable">
        {status === "loading" && statusCode == 0 && (
          <Skeleton active paragraph={{ rows: 4 }} />
        )}
        {mustShowTable && statusCode === 200 && (
          <Table
            bordered
            rowKey={(record) => record.idAddressee}
            dataSource={data}
            columns={columns}
          ></Table>
        )}
        {!mustShowTable && statusCode === 204 && (
          <h3>
            No dispone de destinatarios guardados. Almacene sus datos mediante
            la solicitud de nuevos envíos.
          </h3>
        )}
      </div>
    );
  }
}
