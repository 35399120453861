import React from "react";
import { Modal } from "antd";

export default function AddresseeDeleteModal({
  visible,
  handleDeleteAddressee,
  handleModalVisible,
  addresseeInfo,
}) {
  const modalConfig = {
    title: "Eliminar destinatario",
    action: "deleteAddressee",
    okText: "Eliminar",
    cancelText: "Cancelar",
    onOk: () => handleDeleteAddressee(),
    onCancel: () => handleModalVisible(),
    structure: (
      <div>
        <p>
          ¿Desea eliminar al destinatario "
          <span style={{ fontWeight: "800" }}>{addresseeInfo?.name}</span>"?
        </p>
      </div>
    ),
  };

  return (
    <div className="AddresseeDeleteModal">
      <Modal
        title={modalConfig?.title}
        visible={visible}
        onOk={modalConfig?.onOk}
        onCancel={modalConfig?.onCancel}
        okText={modalConfig?.okText}
        cancelText={modalConfig?.cancelText}
        cancelButtonProps={{ type: "danger", ghost: true }}
        transitionName=""
      >
        {modalConfig?.structure}
      </Modal>
    </div>
  );
}
