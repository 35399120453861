import React, { useState, useEffect } from "react";
import AddresseesTable from "../../components/AddresseesTable/AddresseesTable";
import useAddressees from "../../hooks/useAddressees";
import AddresseeDeleteModal from "../../components/Modals/AddresseeModals/AddresseeDeleteModal";
import NotVerified from "../../components/NotVerified/NotVerified";

export default function Addressees({ userVerified }) {
  const { fetchAddressees, deleteAddressee, addresseesData } = useAddressees();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [addresseeInfo, setAddresseeInfo] = useState({
    idAddressee: null,
    idWholesaler: null,
    name: "",
    direction: "",
    cp: "",
    nomLocation: "",
  });

  const info = JSON.parse(sessionStorage.getItem("wh"));

  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem("wh"));

    if (Object.keys(userInfo).includes("isVer") && userInfo?.isVer === false) {
      console.log("You are not verified. You will be redirected to login page");
    }
    const getAddressees = async () => {
      await fetchAddressees(info?.wh);
    };

    getAddressees();
  }, []);

  useEffect(() => {}, [addresseesData]);

  const handleModalVisible = (addresseeInfo) => {
    setAddresseeInfo(addresseeInfo);
    setModalIsVisible(!modalIsVisible);
  };

  const handleDeleteAddressee = () => {
    const { idAddressee } = addresseeInfo;
    deleteAddressee(idAddressee);
    setModalIsVisible(!modalIsVisible);
  };

  return (
    <>
      {userVerified ? (
        <div className="Addressees">
          <div>
            <h1>Destinatarios</h1>
          </div>
          <AddresseesTable
            addresseesData={addresseesData}
            handleModal={handleModalVisible}
          />
          <AddresseeDeleteModal
            handleDeleteAddressee={handleDeleteAddressee}
            handleModalVisible={handleModalVisible}
            visible={modalIsVisible}
            addresseeInfo={addresseeInfo}
          />
        </div>
      ) : (
        <NotVerified />
      )}
    </>
  );
}
