import React, { useState } from "react";
import { DatePicker, Row, Col, Button, Form } from "antd";
import moment from "moment";

const dateFormat = {
  en: "YYYY-MM-DD",
  sp: "DD-MM-YYYY",
};

function OrderSearchForm(props) {
  const { getFieldDecorator } = props.form;
  const { handleSearch } = props;

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(14, "days"),
    endDate: moment(),
  });

  const handleSearchByDateRange = (e) => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        let codUser = JSON.parse(sessionStorage.getItem("storeArq.data"));
        let searchProps = {
          startDate: values.startDate
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          endDate: values.endDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
          codWholesaler: codUser?.auth?.oid,
        };
        handleSearch(searchProps);
      }
    });
    return false;
  };

  const getDisabledDate = (current, datePickerName) => {
    if (datePickerName === "endDate") {
      return current && current < moment(dateRange.startDate, "DD-MM-YYYY");
    }

    if (datePickerName === "startDate") {
      return current && current > moment(dateRange.endDate, "DD-MM-YYYY");
    }
  };

  const handleDateChange = (e, datePickerName) => {
    setDateRange((prevState) => ({
      ...prevState,
      [datePickerName]: e,
    }));
  };

  return (
    <div className="OrderSearchForm">
      <form onSubmit={handleSearchByDateRange}>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 6 }}
          >
            <div className="datepicker-container">
              <Form.Item label="Desde" size="large">
                {getFieldDecorator("startDate", {
                  initialValue: moment(dateRange.startDate, dateFormat.sp),
                  rules: [
                    { required: true, message: "Debe introducir una fecha." },
                  ],
                })(
                  <DatePicker
                    format={dateFormat.sp}
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(e) => handleDateChange(e, "startDate")}
                    disabledDate={(e) => getDisabledDate(e, "startDate")}
                  />
                )}
              </Form.Item>
            </div>
          </Col>
          <Col lg={{ span: 1 }}></Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 6 }}
          >
            <div className="datepicker-container">
              <Form.Item label="Hasta" size="large">
                {getFieldDecorator("endDate", {
                  initialValue: moment(dateRange.endDate, dateFormat.sp),
                  rules: [
                    { required: true, message: "Debe introducir una fecha." },
                  ],
                })(
                  <DatePicker
                    format={dateFormat.sp}
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(e) => handleDateChange(e, "endDate")}
                    disabledDate={(e) => getDisabledDate(e, "endDate")}
                  />
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: "1rem 0" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 6 }}
          >
            <Button size="large" htmlType="submit">
              Buscar
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default Form.create()(OrderSearchForm);
