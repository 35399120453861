const rolesWeb = [
  {
    rol: "ACCESO_PRE_AD_ADMINISTRADOR",
    accion: [
      { path: "/", sub: null },
      { path: "/destinatarios", sub: null },
    ],
    label: "Administrador",
  },
];

export default rolesWeb;



// tres perfiles: Administrador (acceso a todo), Lectura (solo consultar ciertas cosas), Repartidor(pda's) 
// que no nos afecta porque es para la app movil. 